import PropTypes from 'prop-types';
import React from 'react';
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from 'semantic-ui-react';
import EmailForm from './EmailForm';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <video
      playsInline
      autoPlay
      muted
      loop
      id="myVideo"
      src="https://storage.googleapis.com/plants-project/coverr-wood-1568650087945.mp4"
      poster="https://storage.googleapis.com/plants-project/coverr-wood-1568650087945.jpg"
    />
    <div className="modal-bg"></div>
    <Segment basic>
      <Header
        as="h1"
        content="Clean Air Plants"
        inverted
        style={{
          fontSize: mobile ? '2em' : '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: mobile ? '1.5em' : '3em',
        }}
      />
      <Header
        as="h2"
        content="For every indoor plant sold, we plant a tree on your behalf to help fight climate change"
        inverted
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '1.5em',
        }}
      />
      <EmailForm key={1} />
    </Segment>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

const HomepageLayout = () => (
  <Container fluid>
    <Segment
      inverted
      textAlign="center"
      style={{ minHeight: 800, padding: '1em 0em', position: 'relative' }}
      vertical
    >
      <HomepageHeading />
    </Segment>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              Benefits of indoor plants
            </Header>
            <List style={{ fontSize: '1.33em' }}>
              <List.Item>
                Clean indoor air by absorbing toxins and producing oxygen
              </List.Item>
              <List.Item>
                Boost your mood, productivity, creativity and ability to
                concentrate
              </List.Item>
              <List.Item>Reduce stress and boost your health</List.Item>
            </List>

            <Header as="h3" style={{ fontSize: '2em' }}>
              According to a recent study from Science Magazine
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Planting enough trees can erase 100 years of carbon emissions.
              Trees sequester (store) carbon and produce oxygen through a
              process called photosynthesis.
            </p>
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
            <Image
              bordered
              rounded
              size="large"
              src="https://storage.googleapis.com/plants-project/daniel-hjalmarsson-sEApBUS4fIk-unsplash.jpg"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '0em' }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              "The restoration of trees remains among the most effective
              strategies for climate change mitigation"
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <a
                rel="noreferrer noopener"
                href="https://science.sciencemag.org/content/365/6448/76"
                target="_blank"
              >
                Science Magazine
              </a>
            </p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              "In addition to absorbing carbon dioxide and releasing oxygen
              through photosynthesis, certain common indoor plants may also
              provide a natural way of removing toxic agents."
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <Image
                avatar
                src="https://storage.googleapis.com/plants-project/5e45358b8696fa6955b48f307cbb9ead63716839.jpg"
              />
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://en.wikipedia.org/wiki/NASA_Clean_Air_Study"
              >
                NASA Clean Air Study
              </a>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as="h3" style={{ fontSize: '2em' }}>
          Join the waitlist
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Get on the waitlist. Tell your friends. Fight climate change and
          purify the air in your home.
        </p>
        <EmailForm key={2} />
      </Container>
    </Segment>
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="About" />
              <List link inverted>
                <List.Item as="a">Sitemap</List.Item>
                <List.Item as="a">Contact Us</List.Item>
                <List.Item as="a">Religious Ceremonies</List.Item>
                <List.Item as="a">Gazebo Plans</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Services" />
              <List link inverted>
                <List.Item as="a">DNA FAQ</List.Item>
                <List.Item as="a">How To Access</List.Item>
                <List.Item as="a">Favorite X-Men</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h4" inverted>
                Footer Header
              </Header>
              <p>
                Extra space for a call to action inside the footer that could
                help re-engage users.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </Container>
);

// airtable key7SCYr4MsZsXFkO

export default HomepageLayout;
