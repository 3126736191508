import React from 'react';
import Airtable from 'airtable';

import { Button, Form, Header, Icon } from 'semantic-ui-react';

Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'key7SCYr4MsZsXFkO',
});
const airtable = Airtable.base('apphpWLVPtRS4VsiH');

class EmailForm extends React.Component {
  state = {
    loading: false,
    email: '',
    submitted: false,
  };
  handleSubmit = async () => {
    // console.log(data, e)
    this.setState({ loading: true });
    await airtable('Leads').create([
      {
        fields: { 'Email Address': this.state.email, 'Created At': new Date() },
      },
    ]);
    this.setState({ loading: false, submitted: true });
    window.gtag('event', 'generate_lead');
  };
  render = () => {
    if (this.state.submitted) {
      return (
        <Header as="h2" icon inverted>
          <Icon name="envira" />
          Your home and planet thanks you!
        </Header>
      );
    }
    return (
      <Form size="huge" onSubmit={this.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            name="email"
            type="email"
            placeholder="Enter email address"
            width="10"
            fluid
            onChange={(e, d) => this.setState({ email: d.value })}
            value={this.state.email}
          />
          <Form.Field width="6">
            <Button fluid primary size="huge" loading={this.state.loading}>
              Get Early Access
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };
}

export default EmailForm;
